import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delivery-type-dialog',
  templateUrl: './delivery-type-dialog.component.html',
  styleUrls: ['./delivery-type-dialog.component.scss']
})
export class DeliveryTypeDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeliveryTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data)
     }

  ngOnInit(): void {
  }
  updateDeliveryType(type){
    
    this.dialogRef.close(type);
  }
}
