import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PoliciesService } from 'src/app/shared/services/policies.service';

@Component({
  selector: 'app-cancel-order-confrimation-dialogue',
  templateUrl: './cancel-order-confrimation-dialogue.component.html',
  styleUrls: ['./cancel-order-confrimation-dialogue.component.scss']
})
export class CancelOrderConfirmationDialogueComponent {
  policyList: any;
    constructor( public dialogRef: MatDialogRef<CancelOrderConfirmationDialogueComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,private policiesService: PoliciesService,
        private router: Router) { 
          this.policiesService.getPoliciesList().subscribe((policyList: any) => {
            this.policyList = policyList;
          });
        }
  
   onNoClick(): void {
    this.dialogRef.close(false);
   }

   onYesClick(): void {
    this.dialogRef.close(true);
   }

   public viewPolicy(policyType: any): void {
    // for (let i = 0; i < this.policyList.length; i++) {
    //   if (this.policyList[i].policy_name === 'Return & cancellation policy' && policyType === 'Return & cancellation policy') {
    //     localStorage.setItem('latestUserViewedPolicy', JSON.stringify(this.policyList[i]));
    //     this.router.navigate([]).then(result => { window.open('home/terms-and-policy-view', '_blank'); });
    //   }
    // }
    //home/terms-and-policy-view/cancellation-policy
   // this.router.navigate(['home/terms-and-policy-view/cancellation-policy']);
    window.open('home/terms-and-policy-view/Return & cancellation policy', '_blank');
  }
}
  
