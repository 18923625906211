<div class="container-fluid p-0">
        <div class="row">
            <div class="col">
                <ng-container *ngIf="sliderDetails['title']">
                <div class="title4 pb-27 mb-0 d-flex justify-content-between">
                    <div class="d-flex align-items-baseline col-gap-10">
                    <h2 class="text-left">{{sliderDetails['title'] | translate}} 
                    </h2>
                    <img src="assets/images/icon/flame.gif" alt="" style="height:20px;" *ngIf="sliderDetails['isOfferSlider']">
                </div>
                    <!-- <div class="line"> -->
                    <!-- </div> -->
                    <div class="exploreall">
                        <a (click)="addNewRoute()">
                            {{ 'common.label.exploreall' | translate}} <i class="ti-angle-right"></i>
                        </a>
                    </div>
                </div>
            </ng-container>
                <owl-carousel-o class="product-5 product-m no-arrow" [options]="ProductSliderConfig">
                  <ng-container *ngFor="let product of products">
                    <ng-template carouselSlide *ngIf="product.country_origin && product.product_name && product.product_image_url">
                      <div class="product-box product-wrap">
                          <app-product-card [sliderDetails]="sliderDetails"
                            [product]="product">    
                          </app-product-card>
                      </div>
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>