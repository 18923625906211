import { Component, OnInit, Input, ElementRef, ViewChild, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoreFinderList } from 'src/app/shared/classes/storefinder';
import { StoreFinderService } from 'src/app/shared/services/storefinder.service';
import { GlobalService } from '../../services/global.service';
import { PoliciesService } from '../../services/policies.service';


@Component({
  selector: 'app-terms-and-policy-view',
  templateUrl: './terms-and-policy-view.component.html',
  styleUrls: ['./terms-and-policy-view.component.scss']
})
export class TermsAndPolicyViewComponent implements OnInit {

  termsAndConditionsHeading;
  termsAndConditionsReadMore;
  constructor(public globalService: GlobalService,private policiesService: PoliciesService,private route :ActivatedRoute) {

    this.route.params.subscribe(params => {
      // console.log(params['name'])
      this.policiesService.getApiData(params['name']).subscribe((policyList: any) => {
        if(policyList.is_success){
          let latestUserViewedPolicy =policyList.data.Policy
          this.termsAndConditionsHeading =  latestUserViewedPolicy.Name;
          this.termsAndConditionsReadMore = latestUserViewedPolicy.Content;

        }
      });
    })
  }

  ngOnInit(): void {
    // let latestUserViewedPolicy:any = JSON.parse(localStorage.getItem('latestUserViewedPolicy'));
   
  }
}
