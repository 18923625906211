<!-- <div class="column-tabs"> -->

    <div class="list-categories" [ngStyle]="{'background-image': 'url('+menuDetails?.subCategoryBackgroundImage+')'}">

        <header class="heading-tab">
            <h4 class="heading-title">
                {{menuDetails.category_name}} </h4>
        </header>

        <ul class="tabs allow-sub-list" *ngIf="menuDetails.sub_categories">
            <li class="tab-item" [ngClass]="{'current': (currentTab == menu)}"  *ngFor="let menu of menuDetails?.sub_categories | slice:0:3"
            (click)="navigateCategoryList(menu)"  >
                <span>{{menu?.category_name}}</span>
            </li>
           
        </ul>
<ng-container *ngIf="menuDetails.sub_categories">
        <div class="shop-more" *ngIf="menuDetails?.sub_categories.length > 4">
            <a class="button button-text shop-more-button"  (click)="navigateToCategory()">Show all</a>
        </div>
    </ng-container>
        <!-- <div class="sub-menu-image" [ngStyle]="{'background-image': 'url('+menuDetails?.subCategoryBackgroundImage+')'}">             -->
        <!-- </div>         -->
    </div>
<!-- </div> -->