import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, startWith, delay, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductSearchResult, UpdateReview } from '../classes/product';
import { environment } from 'src/environments/environment';
import { UserLoginRequest, UserLoginResponse } from '../classes/user';
import { ServiceConfigs } from 'src/app/constant/service.config';
import { TranslateService } from '@ngx-translate/core';
import { AddWishListRequest, AddWishListResponse } from '../classes/wishlist';
import { CommonUtil } from '../util/common-util';
import { CUSTOMER_PORTAL_CONSTANT } from 'src/app/constant/constant';
import { AddToCart, DiscardOrderRequest, RemoveWishList } from '../classes/order';
import { OrderService } from './order.service';
import { GlobalService } from './global.service';

const state = {
  products: JSON.parse(localStorage['products'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
  cart: JSON.parse(localStorage['cartItems'] || '[]')
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  public Currency = { name: 'Dollar', currency: 'USD', price: 1 } // Default Currency
  public OpenCart: boolean = false;
  public Products
  public searchProductList = [];
  constructor(private http: HttpClient,
    private toastrService: ToastrService,
    private config: ServiceConfigs,
    private translateService: TranslateService,
    private orderService: OrderService,
    private globalService: GlobalService) { }

  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */

  // Product
  private get products(): Observable<Product[]> {
    this.Products = this.http.get<Product[]>('assets/data/products.json').pipe(map(data => data));
    this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
    return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
  }

  // Get Products
  public get getProducts(): Observable<Product[]> {
    return this.products;
  }

  // Get Products By Slug
  public getProductBySlug(slug: string): Observable<Product> {
    return this.products.pipe(map(items => {
      return items.find((item: any) => {
        return item.title.replace(' ', '-') === slug;
      });
    }));
  }
  public getProductById(id: string, price: number): Observable<any> {
    this.url = this.config.GET_SEARCH_BY_PRODUCT_ID.url;
    let queryParams = new HttpParams();
    queryParams = queryParams.append("product_id", id);
    queryParams = queryParams.append("product_price", price);
    queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);
    queryParams = queryParams.append("customer_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')}`);
    // return this.http.get<ProductSearchResult>(`${this.url}`)
    return this.http.get<ProductSearchResult>(this.url, { params: queryParams })
  }

  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.wishlist);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Wishlist
  public addToWishlist(product: any, requestType?: string, type?: string, quantity?: number): any {
    const wishlistItem = state.wishlist.find(item => item.id === product.id);
    if (!wishlistItem) {
      state.wishlist.push({
        ...product
      })
    }
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    // Need to revamp based on service response 
    let addWishListRequest: AddWishListRequest = {};
    addWishListRequest.customer_id = CommonUtil.getStoreIdFromLocalStorageUserProfile(CUSTOMER_PORTAL_CONSTANT.CUSTOMER_ID);
    addWishListRequest.store_id = CommonUtil.getStoreIdFromLocalStorageUserProfile(CUSTOMER_PORTAL_CONSTANT.STORE_ID);
    addWishListRequest.product_id = product?.product_price?.ProductId || product?.product_id || product?.product_response_model?.product_price?.ProductId;    
    addWishListRequest.quantity = quantity ? quantity : 1;


      this.addToWishlistAPI(addWishListRequest, type).subscribe((response: any) => {
        console.log('response  ===', response.is_success);
        if (response?.is_success)
          this.toastrService.success(this.translateService.instant('myprofile.mywishlist.label.toasteraddedwishlist'), this.translateService.instant('myprofile.mywishlist.label.success!'));
      });

    return true
  }


  // AddtoCart
  public addToCartItem(product: Product, quantity?: number): any {
    console.log('addToCartItem ====', product);

    let addToCart: AddToCart = {};
    addToCart = CommonUtil.prepareAddCart(product, quantity);
    this.orderService.addToCartItem(addToCart).subscribe((removeListResponse: any) => {
      //console.log('removeListResponse =', removeListResponse);
      if (removeListResponse.is_success) {
        this.globalService.setCartCountState('getCartCount');
        this.toastrService.success(this.translateService.instant('myprofile.mywishlist.label.toasteraddedcart'), this.translateService.instant('myprofile.mywishlist.label.success!'));
      } else if (removeListResponse?.message === CUSTOMER_PORTAL_CONSTANT.ADDCART_ERROR) {
        this.toastrService.error(this.translateService.instant(CUSTOMER_PORTAL_CONSTANT.ADDCART_ERROR_MESSAGE), this.translateService.instant('myprofile.mywishlist.label.error!'));
      }

    });


    return true;
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    console.log('removeWishlistItem ====', product);

    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));

    let removeWishListRequest: RemoveWishList = {};
    removeWishListRequest = CommonUtil.prepareRemoveWishList(product);
    this.orderService.removeWishList(removeWishListRequest).subscribe((removeListResponse: any) => {
      if (removeListResponse.is_success)
        this.toastrService.success(this.translateService.instant('myprofile.mywishlist.label.toasterremovewishlist'), this.translateService.instant('myprofile.mywishlist.label.success!'));

    });


    return true
  }


  // Remove Group Wishlist items
  public removeGroupWishlistItem(product: Product, requestType?: string, type?: string): any {
    console.log('removeGroupWishlistItem ====', product);

    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));

    let discardOrderRequest: DiscardOrderRequest = {};
    discardOrderRequest = CommonUtil.prepareRemoveGroupCartWishList(product, CUSTOMER_PORTAL_CONSTANT.SHOPPING_CART);
    this.orderService.removeGroupCartWishList(discardOrderRequest, 'realAPI').subscribe((removeListResponse: any) => {
      //console.log('removeListResponse =', removeListResponse);
      if (removeListResponse.is_success)
        this.toastrService.success(this.translateService.instant('myprofile.mywishlist.label.toasterremovewishlist'), this.translateService.instant('myprofile.mywishlist.label.success!'));

    });


    return true
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find(item => item.id === product.id)
    if (!compareItem) {
      state.compare.push({
        ...product
      })
    }
    //this.toastrService.success('Product has been added in compare.');
    this.toastrService.success(this.translateService.instant('myprofile.mywishlist.label.toastercomparewishlist'), this.translateService.instant('myprofile.mywishlist.label.success!'));
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.cart);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Cart
  public addToCart(product): any {
    const cartItem = state.cart.find(item => item.id === product.id);
    const qty = product.quantity ? product.quantity : 1;
    const items = cartItem ? cartItem : product;
    const stock = this.calculateStockCounts(items, qty);

    if (!stock) return false

    if (cartItem) {
      cartItem.quantity += qty
    } else {
      state.cart.push({
        ...product,
        quantity: qty
      })
    }

    this.OpenCart = true; // If we use cart variation modal
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true;
  }

  // Update Cart Quantity
  public updateCartQuantity(product: Product, quantity: number): Product | boolean {
    return state.cart.find((items, index) => {
      if (items.id === product.id) {
        const qty = state.cart[index].quantity + quantity
        const stock = this.calculateStockCounts(state.cart[index], quantity)
        if (qty !== 0 && stock) {
          state.cart[index].quantity = qty
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true
      }
    })
  }

  // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity
    const stock = product.stock
    if (stock < qty || stock == 0) {
      this.toastrService.error(this.translateService.instant('myprofile.mywishlist.label.toastercantaddmorewishlist') + stock + this.translateService.instant('myprofile.mywishlist.label.items'));
      //this.toastrService.error('You can not add more items than available. In stock '+ stock +' items.');
      return false
    }
    return true
  }

  // Remove Cart items
  public removeCartItem(product: Product): any {
    const index = state.cart.indexOf(product);
    state.cart.splice(index, 1);
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true
  }

  // Total amount 
  public cartTotalAmount(): Observable<number> {
    return this.cartItems.pipe(map((product: Product[]) => {
      return product.reduce((prev, curr: Product) => {
        let price = curr.price;
        if (curr.discount) {
          price = curr.price - (curr.price * curr.discount / 100)
        }
        return (prev + price * curr.quantity) * this.Currency.price;
      }, 0);
    }));
  }

  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    return this.products.pipe(map(product =>
      product.filter((item: Product) => {
        if (!filter.length) return true
        const Tags = filter.some((prev) => { // Match Tags
          if (item.tags) {
            if (item.tags.includes(prev)) {
              return prev
            }
          }
        })
        return Tags
      })
    ));
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {

    if (payload === 'ascending') {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'a-z') {
      return products.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'z-a') {
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else if (a.price < b.price) {
          return 1;
        }
        return 0;
      })
    }
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 30) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 3) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else if (currentPage == totalPages ) {
      startPage = currentPage - paginateRange  + 1 ;
      endPage = currentPage;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }


  public url: string;
  // searchByProduct
  public searchByProduct(pageIndex: number, pageSize: number, search: string, isSearchLandingPage: boolean): Observable<ProductSearchResult> {
    this.url = (environment.mock) ? this.config.GET_SEARCH_BY_PRODUCT.mockUrl : this.config.GET_SEARCH_BY_PRODUCT.url;
    let queryParams = new HttpParams();
    if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
          queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
        }
        if(isSearchLandingPage) {
          queryParams = queryParams.append("page_index", pageIndex);
          queryParams = queryParams.append("page_size", pageSize);
        }
        queryParams = queryParams.append("keyword_term", search);
        queryParams = queryParams.append("is_search_product_landing_page", isSearchLandingPage);
        queryParams = queryParams.append("sort_by", 'Position');
        queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);
    return this.http.get<any>(this.url, { params: queryParams }).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
    );
  }
  public searchByProductAndCategoty(pageIndex: number, pageSize: number, search: string, isSearchLandingPage: boolean): Observable<ProductSearchResult> {
    this.url = (environment.mock) ? this.config.GET_SEARCH_BY_PRODUCT_AND_CATEGORY.mockUrl : this.config.GET_SEARCH_BY_PRODUCT_AND_CATEGORY.url;
    let queryParams = new HttpParams();
    if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
          queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
        }
        if(isSearchLandingPage) {
          queryParams = queryParams.append("page_index", pageIndex);
          queryParams = queryParams.append("page_size", pageSize);
        }
        queryParams = queryParams.append("keyword_term", search);
        queryParams = queryParams.append("is_search_product_landing_page", isSearchLandingPage);
        queryParams = queryParams.append("sort_by", 'Position');
        queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);
    return this.http.get<any>(this.url, { params: queryParams }).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
    );
  }
  private addToWishlistAPI(addWishListRequest: AddWishListRequest, type?: string): Observable<any> {
    this.url = (environment.mock) ? this.config.ADD_WISH_LIST.mockUrl : this.config.ADD_WISH_LIST.url;

    return this.http.post<AddWishListResponse>(`${this.url}`,
      addWishListRequest)
  }
  
  public UpdateReview(reviewRequest: UpdateReview): Observable<any> {
    this.url = (environment.mock) ? this.config.UPDATE_REVIEW.mockUrl : this.config.UPDATE_REVIEW.url;

    return this.http.post<any>(`${this.url}`,
    reviewRequest)
  }
  //related items
  public getRelateditems(productId: number, pageIndex: number, pageSize: number): Observable<any> {
    this.url = (environment.mock) ? this.config.GET_RELATEDITEMS.mockUrl : this.config.GET_RELATEDITEMS.url;
    let queryParams = new HttpParams();
    if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
      queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
    }
    queryParams = queryParams.append("product_id", productId);
    queryParams = queryParams.append("page_index", pageIndex);
    queryParams = queryParams.append("page_size", pageSize);
    queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);

    return this.http.get<any>(this.url, { params: queryParams }).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
    );
  }
  public getManufacturerProducts(manufacturerId: number, pageIndex: number, pageSize: number): Observable<any> {
    this.url = (environment.mock) ? this.config.GET_MANUFACTURERITEMS.mockUrl : this.config.GET_MANUFACTURERITEMS.url;
    let queryParams = new HttpParams();
    if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
      queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
    }
    queryParams = queryParams.append("manufacturer_ids", manufacturerId);
    queryParams = queryParams.append("page_index", pageIndex);
    queryParams = queryParams.append("page_size", pageSize);
    queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);

    return this.http.get<any>(this.url, { params: queryParams }).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
    );
  }
  public getReviews(productId: number, pageIndex?: number, pageSize?: number): Observable<any> {
    this.url = (environment.mock) ? this.config.GET_REVIEWS.mockUrl : this.config.GET_REVIEWS.url;
    let queryParams = new HttpParams();
    if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
      queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
    }
    queryParams = queryParams.append("product_id", productId);
    queryParams = queryParams.append("page_index", pageIndex);
    queryParams = queryParams.append("page_size", pageSize);
    queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);

    return this.http.get<any>(this.url, { params: queryParams }).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
    );
  }

    // AddtoCart
    public removeCart(product: Product, quantity?: number): any {  
      let addToCart: AddToCart = {};
      addToCart = CommonUtil.prepareAddCart(product, quantity);
      this.orderService.removeCartItem(addToCart).subscribe((removeListResponse: any) => {
        //console.log('removeListResponse =', removeListResponse);
        if (removeListResponse.is_success)
          //this.toastrService.success(this.translateService.instant('myprofile.mywishlist.label.toasterremovewishlist'), this.translateService.instant('myprofile.mywishlist.label.success!'));
          this.toastrService.success(this.translateService.instant('myprofile.mywishlist.label.toasteraddedcart'), this.translateService.instant('myprofile.mywishlist.label.success!'));
  
      });
  
  
      return true
    }

    public getCartCount(): Observable<ProductSearchResult> {
      this.url = (environment.mock) ? this.config.GET_CART_COUNT.mockUrl : this.config.GET_CART_COUNT.url;
  
      let queryString: string = '?customer_id=' + `${CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')}`
        + '&store_id=' + `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`
        + '&shopping_cart_type=ShoppingCart';
  
      return this.http.get<ProductSearchResult>(`${this.url}${queryString}`).pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }
}