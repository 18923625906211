import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppSettings } from '../classes/user';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

    isRefreshingToken = false;
    paymentUrl: string = environment.payment_merchant_url;
    paymentId: string = environment.payment_id;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private injector: Injector, private authService: AuthService, private toastrService: ToastrService) {
        //console.log('Interceptor constructor ===============');
    }

    addToken(req: HttpRequest<any>, token?: string): HttpRequest<any> {
        let appSettings: AppSettings = {};
        let Language: string;
        if (localStorage.getItem('appSettings') !== null) {
            appSettings = JSON.parse(localStorage.getItem('appSettings'));
            Language = appSettings.language;
        } else {
            Language = 'en';
        }

        if (req.url == this.paymentUrl){
            return req.clone({ setHeaders: {  'Authorization': 'Basic ' + btoa(this.paymentId) } });
        }

        console.log(req.url)
        if (token && req.url != this.paymentUrl) {
            if(req.method == 'GET' && Language == 'ar') {
                return req.clone({ setHeaders: { 'Authorization': 'Bearer ' + token } ,setParams:{language_culture: 'ar-QA'} });
            } else {
            return req.clone({ setHeaders: { 'Authorization': 'Bearer ' + token } });
            }
        }  else if (token && req.url == this.paymentUrl){
            return req.clone({ setHeaders: {  'Authorization': 'Basic ' + btoa(this.paymentId) } });
        }
        else if ( req.url != this.paymentUrl){
            if(req.method == 'GET' && Language == 'ar'){
                return req.clone({ setParams:{language_culture: 'ar-QA'} });
            }  else {

            return req.clone();
            }
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        //console.log('REQUEST =', req);

        return next.handle(this.addToken(req, this.authService.getAccessToken())).pipe(
            // map(res => {
            //    // console.log("Passed through the interceptor in response", res);
            //     return res;
            //  }),
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (event.body.is_success === false) {
                        if (event.body.message !== '') {
                            // this.toastrService.error(event.body['message'], 'Error');
                        }
                    }
                }
                return event;
            }),
            catchError(error => {
                console.log('ERROR 9999999999999999 =', error);

                if (error instanceof HttpErrorResponse) {
                    console.log(error.message)
                    if (!error.ok && error.message) {
                        this.toastrService.error(error.message)
                    }
                    switch ((<HttpErrorResponse>error).status) {
                        case 400:
                            return this.handle400Error(error);
                        case 401:
                            return this.handle401Error(req, next);
                        case 404:
                            return observableThrowError(error);
                        default:
                            return observableThrowError(error);
                    }
                } else {
                    return observableThrowError(error);
                }
            }));
    }

    handle400Error(error) {
        if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            return observableThrowError('');
        }

        return observableThrowError(error);
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req);
    }
}

