import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CUSTOMER_PORTAL_CONSTANT } from 'src/app/constant/constant';
import { Product } from 'src/app/shared/classes/product';
import { ProductService } from 'src/app/shared/services/product.service';
import { CartModalComponent } from '../../modal/cart-modal/cart-modal.component';
import { QuickViewComponent } from '../../modal/quick-view/quick-view.component';
import { GlobalService } from 'src/app/shared/services/global.service';

@Component({
  selector: 'app-product-card-tab',
  templateUrl: './product-card-tab.component.html',
  styleUrls: ['./product-card-tab.component.scss']
})
export class ProductCardTabComponent implements OnInit {

  @Input() product: Product;
  @Input() brandLogo: string;
  @Input() currency: any = this.productService.Currency; // Default Currency
  @Input() cartModal: boolean = false; // Default False
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;



  constructor(private productService: ProductService,private router : Router,
    private toastrService : ToastrService, private globalService: GlobalService) { }

  ngOnInit(): void {
    if(this.product.unit_of_measure == 'KG' || this.product.unit_of_measure == 'GR') {
      this.product.offer_price = Number((this.product.offer_price * 1000).toFixed(2));
      this.product.was_price = Number((this.product.was_price * 1000).toFixed(2));
    }
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants


  addToCart(product: any) {
    this.productService.addToCart(product);
  }
  addToCartList(product: any, requestType: string) {
    if (!(JSON.parse(localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER))?.customer_id)){
      this.toastrService.error('Login to continue','Error');
      this.router.navigateByUrl('/public/login');
      return
    } 
    if(!this.globalService.customerDeliveryAddress) {
      this.toastrService.error('Add delivery address', 'Error');
      this.router.navigateByUrl('/pages/myaddress');
      return;
    }
    let deliveryType = localStorage.getItem('CustomerDeliveryType');
    let is_instant = product?.is_instant
    if( deliveryType == 'Instant' && !is_instant){
      this.toastrService.error('Instant delivery not availble for this product', 'Error');
      // this.router.navigateByUrl('/public/login');
      return
    }
    // ADD WISHLIST & ADDCART same functionality here
        this.productService.addToCartItem(product);
        //this.isFavourite = !this.isFavourite;
  }
  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }
  getValue(offerPrice: any, type: string): any {
    offerPrice = offerPrice != 0 ? offerPrice : '0.00'
    if(offerPrice) {
      let parts = offerPrice.toString().split('.');
        if(type === 'Int') {
          return parts[0] | 0;
        } else {
          return (parts[1]) ? parts[1].length > 1 ? parts[1] : parts[1]+'0' : '00';     
        }
    }
  }
}
